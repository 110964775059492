import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "@reach/router";
import { Button, Link, Stack, Text } from "basis";

import {
  AcceptedCards,
  Container,
  ErrorMessage,
  Flex,
  LoadingMessage,
} from "../../../components";
import {
  FACTOR_NAME,
  prepareWidget,
  useCustomerAuth,
} from "../../../core/auth";

import { useThreatMetrix } from "../../../hooks";
import { BUTTON_TEXT, LoginError, ROUTES, Scene } from "../../../layout/wallet";

import { useOrder } from "../../../layout/checkout";

import { logger } from "../../../core";
import { customerOktaConfig, lscBaseUrl } from "../../../core/config";
import { MODE } from "../../../core/auth/constants";
import { CustomerOktaContainer } from "../../../layout/shared";
import { MERCHANT_GROUP } from "../../../core/constants";

const Login = () => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [loginError, setLoginError] = useState();

  const { startProfile, isLoading } = useThreatMetrix();
  const location = useLocation();
  const widgetRef = useRef();
  const { start } = useCustomerAuth();
  const { loading: fetchingOrder, error, order, params } = useOrder();

  useEffect(() => {
    startProfile();
  }, []);

  useEffect(() => {
    if (fetchingOrder) {
      return;
    }

    if (isLoading) {
      return;
    }

    if (!widgetRef.current) {
      return;
    }

    if (!order?.transactionId) {
      return;
    }

    if (loginError) {
      return;
    }

    start();

    const widget = prepareWidget({
      oktaConfig: customerOktaConfig,
      mode: MODE.CUSTOMER,
      targetElement: widgetRef.current,
      title: "Login",
      usernamePlaceholder: "Latitude ID",
      redirectURL: `${ROUTES.WALLET_PAYMENT}${location.search}`,
      allowedFactors: [FACTOR_NAME.SMS],
      customButtons: [
        {
          title: BUTTON_TEXT.MANUAL_PAYMENT,
          onClick: () => {
            window.location.href = `${ROUTES.MANUAL_PAYMENT}${location.search}`;
          },
        },
      ],
      afterAuth: () => setAuthenticated(true),
      onError: (code, description) => {
        logger.error(`Wallet login error. ${code}: ${description}`);

        setLoginError(code);
      },
    });

    return () => {
      widget.off();
      widget.remove();
    };
  }, [fetchingOrder, location.search, params, order, error, start, loginError]);

  if (fetchingOrder || isAuthenticated || isLoading) {
    return (
      <Scene merchantLogoURL={order?.urlLogo} country={order?.country}>
        <LoadingMessage />
      </Scene>
    );
  }

  if (error?.message) {
    return (
      <Scene merchantLogoURL={order?.urlLogo} country={order?.country}>
        <ErrorMessage
          type={error?.message}
          additionalParams={{
            minimumAmount: order?.minimumAmount,
            currency: order?.currency,
            urlCancel: order?.urlCancel,
            transactionId: order?.transactionId,
          }}
        />
      </Scene>
    );
  }

  return (
    <Scene merchantLogoURL={order?.urlLogo} country={order?.country}>
      <Container margin="4 0" padding="2" maxWidth={1100}>
        <Stack gap="6">
          <Link href={order?.urlCancel} newTab={false}>
            &lt; Back to cart
          </Link>
          <Text align="left" textStyle="heading4">
            Pay with Latitude Wallet
          </Text>
        </Stack>
      </Container>

      <Container bg="white" margin="4 0" maxWidth={1100} shadow>
        <Container bg="white" margin="4 0" padding="4 6" maxWidth={400}>
          <Flex>
            <Text>
              <strong>Your purchase</strong>
            </Text>
            <Text>${order?.amount}</Text>
          </Flex>
        </Container>
      </Container>

      <Container bg="white" margin="4 0" maxWidth={1100} shadow>
        <Container bg="white" margin="4 0" padding="10 6" maxWidth={400}>
          <Stack gap="4">
            <Text align="left" textStyle="heading4">
              Login
            </Text>

            <Stack>
              <Text textStyle="body2" align="left">
                Enter your Latitude ID to receive an one time verification code.
                The code will be sent to the mobile number linked to your
                Latitude account.
              </Text>
            </Stack>

            {loginError && (
              <LoginError
                errorCode={loginError}
                additionalDesc="Please pay with credit card / account number below."
              >
                <Button
                  variant="secondary"
                  onClick={() => {
                    window.location.href = `${ROUTES.MANUAL_PAYMENT}${location.search}`;
                  }}
                >
                  {BUTTON_TEXT.MANUAL_PAYMENT}
                </Button>
              </LoginError>
            )}

            <CustomerOktaContainer ref={widgetRef} />

            <AcceptedCards
              country={order?.country}
              showAppleAUCreditLine={
                order?.merchantGroup === MERCHANT_GROUP.APPLE_AU
              }
            />

            <Link
              href={`${ROUTES.WALLET_PAYMENT}${location.search}`}
              newTab={false}
            >
              Back to login
            </Link>

            <Link href={`${lscBaseUrl}/faq`} newTab>
              Need help signing in?
            </Link>
          </Stack>
        </Container>
      </Container>
    </Scene>
  );
};

export default Login;
